import './App.css';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar'
import Services from './pages/Services';
import Footer from './components/Footer';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import AboutUS from './pages/AboutUS';
import Error from './pages/Error';
import DesignInstall from './pages/DesignInstall';

function App() {
  return (
    <>
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/services" element={<Services />} />
          <Route path="/design_install" element={<DesignInstall />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer/>
      </BrowserRouter>

    </>
  );
}

export default App;
