import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNo: '',
        city: '',
        state: '',
        companyName: '',
        range: 0,
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'contactNo') {
            const isValid = /^[6-9][0-9]{9}$/.test(value);
            e.target.setCustomValidity(isValid ? '' : 'Please enter a 10-digit Valid Phone Number');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.success("Submitted Successfully");
        setFormData({
            name: '',
            email: '',
            contactNo: '',
            city: '',
            state: '',
            companyName: '',
            range: 0,
            message: ''  
        })
    };

    return (
        <>
            <ToastContainer />
            <section className='contact'>
                <h1 className='heading-style'>Contact Us <i className="fa-regular fa-address-book" style={{ color: "#FF7722" }}></i></h1>
                <div className="contact-content">
                    <div className="contact-right">
                        <h2 className='para-style text-align-left'>We're Always Happy to Talk About Solar Energy...</h2>
                        <p className='para-style text-align-left'>If you want to know more about solar energy, about us or just have a no obligation discussion, don't hesitate just fill the form and send it to us. We also offer a free, no-obligation visit to your space to assess its solar potential</p>
                        <h2 className='para-style text-align-left'><i className="fa-solid fa-location-dot" style={{ color: "#090c9b" }}></i> Office Address:</h2>
                        <p className='para-style text-align-left' style={{ marginLeft: "3.5rem" }}>3rd Floor, Shristi Apartment,
                            Near Indian Oil Petrol Pump, Turning Point
                            Shankar Nagar, Raipur (C.G.), 492001
                        </p>
                        <h2 className='para-style text-align-left'><i className="fa-solid fa-address-card" style={{ color: "#090c9b" }}></i> Contact:</h2>
                        <p className='para-style text-align-left' style={{ marginLeft: "3.5rem" }}>+91 8827824618 <br />saffronnsheen@gmail.com <br />keshariya@yahoo.com </p>
                    </div>
                    <div className="contact-left">
                        <div className='contact-left-content'>
                            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="name">Name:</label>
                                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required placeholder='Enter Your Name' />
                                </div>

                                <div>
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required placeholder='Enter Your Email' />
                                </div>

                                <div>
                                    <label htmlFor="contactNo">Contact Number:</label>
                                    <input type="tel" id="contactNo" name="contactNo" value={formData.contactNo} onChange={handleChange} required placeholder='Enter Your Contact No.' pattern="[6-9][0-9]{9}" />
                                </div>

                                <div>
                                    <label htmlFor="city">City:</label>
                                    <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required placeholder='Enter Your City' />
                                </div>

                                <div>
                                    <label htmlFor="state">State:</label>
                                    <input type="text" id="state" name="state" value={formData.state} onChange={handleChange} required placeholder='Enter Your State' />
                                </div>

                                <div>
                                    <label htmlFor="companyName">Company Name:</label>
                                    <input type="text" id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} placeholder='Enter Your Company Name' />
                                </div>

                                <div>
                                    <label htmlFor="range">Range: <span>{formData.range}KW</span></label>
                                    <input type="range" id="range" name="range" min="0" max="100" value={formData.range} onChange={handleChange} required />
                                </div>

                                <div>
                                    <label htmlFor="message">Your Message:</label>
                                    <textarea id="message" name="message" value={formData.message} onChange={handleChange} required placeholder='Enter Your Message'></textarea>
                                </div>

                                <div>
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7437.0796708584885!2d81.65376179357906!3d21.250088600000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28dd191b628c2d%3A0x857463f87e1219d0!2ssaffronsheen!5e0!3m2!1sen!2sin!4v1714155274962!5m2!1sen!2sin" width="100%" height="400" style={{ border: 0, padding: "1rem", borderRadius: "2rem" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="SaffronSheen Location"></iframe>
            </section>
        </>
    );
}

export default Contact;
