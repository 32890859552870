import React from 'react'
import faq_logo from '../assets/faq_logo.jpg';

const FAQ = () => {
    return (
        <section className='faq'>
            <div className="faq-inner">
                <img src={faq_logo} alt="faq's" />
                <div className="faq-content">
                    <p>What is the Price for Solar Design ?</p>
                    <ul>
                        <li>SaffronnSheen's Solar Design charges are very nominal.</li>
                        <li>You can hire us on Monthly basis. Check out our services packages</li>
                        <li>You can also pay us as per project design basis.</li>
                        <li>Contact us to get quotation</li>
                    </ul>
                    <p>What data do you require to start the Project ?</p>
                    <ul><li>Very basic data like Google Location, 2D site survey layout and site video.</li></ul>
                    <p>How can SaffronnSheen Design Portal help us?</p>
                    <ul>
                        <li>Sign-up on our portal on website and upload the site survey data </li>
                        <li>Our technical team will analysis the data and will provide suitable solutions</li>
                        <li>You can monitor the Project progress and solar designs provided by us</li>
                        <li>Download your project from our portal</li>
                    </ul>
                    <p>How Accurate is Shadow Analysis?</p>
                    <ul><li>Shadow analysis is most possible accurate; we consider location of the Solar Plant on Earth and shortest day & longest day of the year which helps to produce more accurate results.</li></ul>
                </div>
            </div>

        </section>
    )
}

export default FAQ
