import React from 'react'

const DesignInstall = () => {
  return (
    <>
      <section className='design'>
        <h1 className='heading-style' >Design <i className="fa-solid fa-compass-drafting"></i></h1>
        <div className="design-content">
          <p className='para-style'>Our solar PV systems are tailored to meet your specific energy needs and site requirements. Here's how we do it:
          </p>
          <div className="service-info">
            <ul>
              <li><span>Initial Consultation:</span> We start by understanding your energy goals, budget, and site conditions through an in-depth consultation.
              </li>
              <li><span>Site Assessment:</span> Our team conducts a comprehensive site assessment to evaluate factors such as solar exposure, shading, roof condition, and structural integrity.
              </li>
              <li><span>System Design:</span> Using state-of-the-art software and advanced modeling techniques, we design a customized solar PV system optimized for maximum energy production and efficiency.
              </li>
              <li><span>Component Selection:</span> We carefully select high-quality solar panels, inverters, and mounting systems from trusted manufacturers to ensure reliability and performance.
              </li>
              <li><span>Financial Analysis:</span> We provide a detailed financial analysis, including cost estimates, potential savings, and available incentives, to help you make an informed decision.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className='design'>
        <h1 className='heading-style' >Installation Process <i className="fa-solid fa-screwdriver-wrench"></i></h1>
        <div className="design-content">
          <p className='para-style'>Once the design is finalized and permits are obtained, our skilled technicians will install your solar PV system with precision and care:
          </p>
          <div className="service-info">
            <ul>
              <li><span>Preparation:</span> Our team prepares the site, ensuring proper safety measures are in place and all necessary equipment and materials are readily available.
              </li>
              <li><span>Panel Installation:</span> Solar panels are securely mounted on your roof or ground-mounted racking system using industry-leading techniques to withstand environmental conditions.
              </li>
              <li><span>Inverter Installation:</span> We install the inverter, which converts DC power generated by the solar panels into usable AC power for your home or business.
              </li>
              <li><span>Electrical Connection:</span> Our certified electricians connect the solar PV system to your electrical panel, ensuring seamless integration with your existing electrical infrastructure.
              </li>
              <li><span>Testing and Commissioning:</span> We conduct rigorous testing to verify the functionality and performance of the system, making any necessary adjustments to optimize its operation.
              </li>
              <li><span>Monitoring and Maintenance:</span> Our commitment doesn't end with installation. We offer ongoing monitoring and maintenance services to ensure your solar PV system operates at peak efficiency and continues to deliver clean, renewable energy for years to come.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

export default DesignInstall
