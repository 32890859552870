import React, { useEffect, useState } from 'react';
import Accordion from '../components/Accordion'
import logo_heading from "../assets/logo-heading.png"

const Home = () => {
  const [scrollY, setScrollY] = useState(0);
  const textOptions = "Registered Vendor on National Portal (PM - Surya Ghar Muft Bijli Yozna) for Rooftop Solar, We Provide From Beginning To End Services/solution For Solar Panel, Solar Installation including Solar Panels, Mounting Structure, Inverters and other required Products.";

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY * 0.3);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* ----hero start---- */}
      <section className='hero'>
        <div className="hero-content" style={{ transform: `translateY(${scrollY}px)` }}>
          <div className="hero-head">
            <h1>Welcome To <span><img src={logo_heading} alt="" /></span></h1>
            <p>One-Stop Shop For Sustainable Solar Solutions.</p>
            <div className='marquee-container'>
              <span className='marquee-text'>{textOptions}</span>
            </div>
          </div>
        </div>
      </section>
      {/* ----hero end---- */}

      {/* ----company profile start---- */}
      <section className='company-profile'>
        <h1 className='heading-style'>Company Profile <i className="fa-regular fa-building fa-logo"></i></h1>
        <p className='para-style'><span className='blue-text'>SaffronnSheen</span> is leading the solar revolution, making the process of going solar a smooth, efficient, and highly lucrative experience. But we also understand that every home, every homeowner, and every install is different. We conduct a comprehensive solar consultation with you, mapping out the best possible money-saving solar install for your needs. The “SaffronnSheen advantage" means that your solar will be customer designed just for you, maximizing your energy ROI and blending in with the aesthetics of your home. Regardless, how much energy your solar panels produce, we guarantee you will save money compared to your previous bill.
          <br /> <br />
          <span className='blue-text'>SaffronnSheen</span> focus on engineering design & Consultancy. We have very wide experience of solar power plant design, Engineering and consultancy work. This group is incepted by first generation entrepreneur. They are well qualified and have good entrepreneur skills.
          <br /><br />
          <span className='blue-text'>SaffronnSheen</span> is A Registered Vendor on National Portal <span style={{'color':'brown'}}>(PM - Surya Ghar Muft Bijli Yozna)</span> for Rooftop Solar Solutions. Also we are registered company as Udyam Akanksha as expressing our intent to set up a Micro - Service by DISTRICT TRADE & INDUSTRIES CENTRE, RAIPUR (C.G.)
          <br /><br />
          To add more values in Solar industry, we always give priority to adopt latest technology and upgrading the infrastructure in our company. Heaven designs helps small business, new entrepreneurs, MSME and big corporate solar companies. We believe growing our clients solar business is real success for us!
        </p>
      </section>
      {/* ----company profile end---- */}

      {/* ----Who we are start---- */}
      <section className="timeline-section">
        <h1 className='heading-style'>Who We Are ?</h1>
        <div className="timeline-items">
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Our Vision</div>
            <div className="timeline-content">
              <p>We move ahead with the vision, to be a global, innovative and competitive Solar Energy enterprise and will provide our best solutions to various customers ranging from small villagers to big corporate and Government sectors.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Our Mission</div>
            <div className="timeline-content">
              <p>Our mission is to aggressively drive the cost of solar power downward while maintaining first-class quality and service. By means of our lean and integrated capabilities, we aim to aid businesses worldwide to go green not only to reduce their carbon footprint but also because our programs make economic sense.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Our Values</div>
            <div className="timeline-content">
              <p>At SaffronnSheen, trust is a key component of our operating philosophy. That's why we provide in-house design, installation, maintenance and support. We make sure that every stage of the process is handled by our team of experts, and no one else. This not only reduces costs, it gives you the peace of mind to know that SaffronnSheen is with you each step of the way.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-date">Our Work Strategy</div>
            <div className="timeline-content">
              <p>At SaffronnSheen, quality is super important. We work with the best companies worldwide to get the best stuff for your systems. We offer lots of options for renewable energy, and we're with you every step of the way, from planning to fixing. Our team makes sure your solar setup fits your needs and is looked after from start to finish.</p>
            </div>
          </div>
        </div>
      </section>

      {/* ----Why US start---- */}
      <section className="why-us">
        <h1 className='heading-style' style={{ marginBottom: "3rem" }}>Why Us ? <i className="fa-solid fa-person-circle-question fa-logo"></i></h1>
        <p className='para-style'>We have developed a team of experience technician to provide you convenience of Clean energy.</p>
        <div className="accordion-container">
          <Accordion title="Solar Site Assessment" content="Our Team will visit for Solar Site Assessment on a Single phone call and explain you the detailed plan and possibilities." />

          <Accordion title="Customized Rooftops" content="Team SaffronnSheen Very well understand the Indian house structure and is quite efficient to design robust, unique and long lasting solar rooftop system and GI structure." />

          <Accordion title="Low Maintenance & Life Time Customer Support" content="We believe in long term bonding with our customers and Team SaffronnSheen is ready to assist you for all your maintenance calls for lifelong, also our solar rooftop plants are designed in a way that you will be getting really low maintenance per year. We also help you to get the Government subsidies and discounts. So you leave your worries on us and Go Solar." />
        </div>
      </section>
      {/* ----Why US end---- */}
    </>
  )
}

export default Home
