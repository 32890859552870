import React from 'react'

const card = (props) => {
  const { image, heading, text } = props;
  return (
    <div className="card">
      <img src={image} alt="logo" />
      <div>
        <h2 className="heading">{heading}</h2>
        <p className="text">{text}</p>
      </div>
    </div>
  )
}

export default card
