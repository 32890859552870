import React from 'react'
import Card from '../components/card'
import who_are_we_logo from '../assets/who_we_are.jpg';
import our_vision from '../assets/our-vision.jpg';
import our_mission from '../assets/our-mission.jpg';
import our_values from '../assets/our-values.jpg';
import our_strategy from '../assets/strategy.jpg';

const AboutUS = () => {
    return (
        <div>
            {/* ----Who we are start---- */}
            <section className="about" id='about'>
                <img src={who_are_we_logo} alt="who are we" />
                {/* <h1 className='heading-style'>Who We Are ?</h1> */}
                <p className='para-style'>Innovation and best quality services are our primary motto. As a team of young entrepreneurs, we strongly believe that Indian youth is a treasure house of magnanimous energy and it holds the power to develop this economy with efficient and reliant solutions. <br /><br />
                    We supply and support software & survey design tools for energy technologies such as photovoltaic, solar thermal and heat pump design. <br /><br />
                    A passionate team of technocrats which have come together to contribute for the enhancement and development of the planet. Due to increased use of fossil fuel, earth's climate is changed and temperature is also increasing day by day. Huge amount of coal and crude oil is burned every day to run this economy. To save earth's natural environment for our upcoming generation, We are on a mission to make this planet green again


                </p>
                <div className="about-cards">
                    <Card image={our_vision} heading="Our Vision" text="We move ahead with the vision, to be a global, innovative and competitive Solar Energy enterprise and will provide our best solutions to various customers ranging from small villagers to big corporate and Government sectors." />

                    <Card image={our_mission} heading="Our Mission" text="Our mission is to aggressively drive the cost of solar power downward while maintaining first-class quality and service. By means of our lean and integrated capabilities, we aim to aid businesses worldwide to go green not only to reduce their carbon footprint but also because our programs make economic sense." />

                    <Card image={our_values} heading="Our Values" text="At SaffronnSheen, trust is a key component of our operating philosophy. That's why we provide in-house design, installation, maintenance and support. We make sure that every stage of the process is handled by our team of experts. This not only reduces costs, it gives you the peace of mind to know that SaffronnSheen is with you each step of the way." />

                    <Card image={our_strategy} heading="Our Work Strategy" text="At SaffronnSheen, quality is super important. We work with the best companies worldwide to get the best stuff for your systems. We offer lots of options for renewable energy, and we're with you every step of the way, from planning to fixing. Our team makes sure your solar setup fits your needs and is looked after from start to finish." />
                </div>
            </section>
            {/* ----Who we are end---- */}
        </div>
    )
}

export default AboutUS
