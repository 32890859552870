import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import logo from '../assets/logo.png';
import nav_logo from '../assets/nav-logo.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getBackgroundStyle = () => {
        if (isOpen) {
            return screenWidth > 1275 ? "none" : "#090b9bc1";
        }
        return null;
    };

    return (
        <header>
            <nav className={`navbar ${isOpen ? 'active' : ''}`}>
                <NavLink to='/'>
                    <div className="logo">
                        <img src={logo} alt="logo" />
                        <img src={nav_logo} alt="company name" />
                    </div>
                </NavLink>
                <ul
                    className={`nav-links ${isOpen ? 'active' : ''}`}
                    style={{
                        top: isOpen ? '0' : '-600px',
                        background: getBackgroundStyle(),
                    }}
                >
                    <li className='nav-link'><NavLink to="/" onClick={toggleMenu}>Home</NavLink></li>
                    <li className='nav-link'><NavLink to="/about" onClick={toggleMenu}>About</NavLink></li>
                    <li className='nav-link'><NavLink to="/services" onClick={toggleMenu}>Services</NavLink></li>
                    <li className='nav-link'><NavLink to="/design_install" onClick={toggleMenu}>Solar PV System Design & Installation</NavLink></li>
                    <li className='nav-link'><NavLink to="/faq" onClick={toggleMenu}>FAQ</NavLink></li>
                    <li className='nav-link'><NavLink to="/" onClick={toggleMenu}>Hiring</NavLink></li>
                    <li className='nav-link'><NavLink to="/contact" onClick={toggleMenu}>Contact</NavLink></li>
                    <li className='hamburger'>
                        <div className="menu-toggle" onClick={toggleMenu}>
                            <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
                        </div>
                    </li>
                </ul>
                <div className='menu' onClick={toggleMenu}><i className='fas fa-bars'></i></div>
            </nav>
        </header>
    );
};

export default Navbar;
