import React from 'react'
import Accordion from '../components/Accordion'

const Services = () => {
  return (
    <>
      <section className='services'>
        <div className="service-left-div">
          <h1 className='heading-style' >Our Services <i className="fa-solid fa-gears fa-logo"></i></h1>
          <div className="service-info">
          <h2>For Residential Solution</h2>
          <p>Own a solar power plant on your empty roof and earn handsome savings by cutting down your electricity bill by up to 90% and subsidy up to 30%.</p>
          </div>
          <div className="service-info">
          <h2>For Commercial Solution</h2>
          <p>Why paying higher tariffs for your commercial establishment when you can reduce it upto 90% by installing maintenance free solar rooftop power system</p>
          </div>
          {/* <Accordion title="For Residential Solution" content="Own a solar power plant on your empty roof and earn handsome savings by cutting down your electricity bill by up to 90% and subsidy up to 30%." /> */}

          {/* <Accordion title="For Commercial Solution" content="Why paying higher tariffs for your commercial establishment when you can" /> */}

          <Accordion title="3D Analysis and Shadow Analysis" content="We develop entire 3D model of roof in the various 3d softwares and provide shadow analysis report." />

          <Accordion title="PV Syst Energy Generation Report" content="We provide PV Syst energy generation report which can forecast very accurate energy generation for next 25 years." />

          <Accordion title="Permit Design Package" content="We provide complete 	Electrical and Structure design as per AHJ guideline for residential, commercial and ground mount projects." />

          <Accordion title="PE Stamping Service" content="We have tie-up with many stamping vendors and provide very cost effective service for PE stamps" />
        </div>
        <div className="service-right-div"></div>
      </section>
      <section className='we-provide'>
      <div className="we-provide-left-div"></div>
      <div className="we-provide-right-div">
        <h1 className='heading-style'>SaffronnSheen, We Provide <i className="fa-regular fa-handshake fa-logo"></i></h1>
        <p>Rooftop Solar, Ground Mounted Solar EPC's</p>
        <ul>
          <li>Limitless Solar Energy</li>
          <li>Reduce your Carbon Footmark</li>
          <li>Reduced power bills i.e. Cost Effective</li>
          <li>Solar PV system requires Minimal maintenance</li>
          <li>Ease of Installation</li>
          <li>Easy access of power at remote areas</li>
          <li>Secure Investment with Long Term Benefits</li>
        </ul>
        </div>
      
      </section>
    </>
  )
}

export default Services
