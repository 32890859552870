import React from 'react'
import { NavLink } from "react-router-dom";

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return (
        <div className='footer'>
            <div className="contact-info">
                <p><i className="fa-solid fa-location-dot fa-logo"></i>&nbsp;&nbsp; Address: 3rd Floor, Shristi Apartment,
                    Near Indian Oil Petrol Pump, Turning Point
                    Shankar Nagar, Raipur (C.G.), 492001</p>
                <p><i className="fa-solid fa-phone fa-logo"></i>&nbsp;&nbsp; Contact No. +91 8827824618</p>
                <p><i className="fa-solid fa-envelope fa-logo"></i>&nbsp;&nbsp; Email: saffronnsheen@gmail.com</p>
                <p><i className="fa-brands fa-yahoo fa-logo"></i>&nbsp;&nbsp; Yahoo mail: keshariya@yahoo.com </p>
            </div>
            <div className="social-media-logo">
                <i className="fa-brands fa-instagram fa-logo"></i>
                <i className="fa-brands fa-facebook fa-logo"></i>
                <i className="fa-brands fa-whatsapp fa-logo"></i>
            </div>
            <div className='footer-links'>
                <ul>
                    <div>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/">Home</NavLink></li>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/about">About Us</NavLink></li>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/services">Our Services</NavLink></li>
                    </div>
                    <div>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/design_install">Solar PV System Design & Installation</NavLink></li>
                    </div>
                    <div>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/faq">FAQ</NavLink></li>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/">Hiring</NavLink></li>
                        <li className='nav-link' onClick={scrollToTop}><NavLink to="/contact">Contact Us</NavLink></li>
                    </div>

                </ul>
            </div>
        </div>
    )
}

export default Footer
